






import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import Vehicle from '@/model/vehicle'
import { DataTableHeader } from 'node_modules/vuetify/types';
import axios from 'axios';
import API from '@/store/api';

@Component({
  components: {

  },
})
export default class VehicleView extends Vue {

  private vehicle: Vehicle;

  private headers: DataTableHeader[] = [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
    { text: 'Name (international)', value: 'name_int' },
  ];

  created(){
    let id = this.$route.params.id;
    axios.get(API.URL_VEHICLE(id)).then( response => {
      this.vehicle = response.data;
    }).catch( error => {
      window.console.error('Error while loading vehicle information:', error);
    });
  }
}
